const itemListConfig = {
    keywordList: [
        {
            genreId: 1,
            text: 'コミック'
        },
        {
            genreId: 2,
            text: '雑誌',
        },
        {
            genreId: 3,
            text: '新書',
        },
        {
            genreId: 4,
            text: '文庫',
        },
        {
            genreId: 5,
            text: '文芸',
        },
        {
            genreId: 6,
            text: 'ライトノベル・BL',
        },
        {
            genreId: 7,
            text: '絵本・こどもの本',
        },
        {
            genreId: 8,
            text: 'ビジネス',
        },
        {
            genreId: 9,
            text: 'IT',
        },
        {
            genreId: 10,
            text: '自然科学',
        },
        {
            genreId: 11,
            text: '教育・学習参考書',
        },
        {
            genreId: 12,
            text: '人文・社会科学',
        },
        {
            genreId: 13,
            text: '語学・辞典・事典',
        },
        {
            genreId: 14,
            text: '資格試験・検定',
        },

        {
            genreId: 15,
            text: 'アート・デザイン',
        },
        {
            genreId: 16,
            text: '趣味・実用',
        },
        {
            genreId: 17,
            text: 'エンターテインメント',
        },
        {
            genreId: 18,
            text: 'その他',
        },
    ],
    articleList: [
        {
            description: 'バリューブックスの送料について解説！査定額から使い方のポイントまで',
            url: '/endpaper/5895/',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/souryounazeアイキャッチ-scaled.jpg',
        },
        {
            description: 'BOOK BUSにて「パタゴニア ブックフェア」を 開催中！',
            url: '/endpaper/11452/',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/patagoniabookbus01-scaled.jpg',
        },
        {
            description: '【目標 500万円分！】あなたと本屋で贈る「ブック・プレゼント」プロジェクト',
            url: '/endpaper/11411/',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/bookpresent-520x292.png',
        },
        {
            description: '北極冒険家・荻田泰永さんが手がける絵本『PIHOTEK ピヒュッティ-北極を風と歩く-』発売記念トークイベント開催！',
            url: '/endpaper/11368/',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/DSC6680-1-520x292.jpg',
        },
        {
            description: '本だ！ねこだ！おまつりだ！「にゃんとこフェスタ」開催中',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB-520x292.jpg',
            url: '/endpaper/11313/',
        },
        {
            description: 'ゆる言語学ラジオは「ラーメン二郎」でいつか「マフィア」になる？ "言語"の面白さを届けるコンテンツの裏側を直接聞いてみた！',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/300094492_572828041236145_7396248089810400795_n-520x292.jpg',
            url: '/endpaper/11257/',
        },
        {
            description: '本だったノート – 古紙になるはずだった本からできたノート',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/hondattanote001-520x292.jpg',
            url: '/endpaper/11189/',
        },
        {
            description: '負けず嫌いとインパクト |『B Corpハンドブック』日本語版まえがき 【文＝鳥居希】',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/HRM1350-520x292.jpg',
            url: '/endpaper/11147/',
        },
        {
            description: '積み本を売って「ゆる言語学ラジオ」グッズを手に入れよう！',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/295987670_765563514487205_6169102135246835501_n-520x292.jpg',
            url: '/endpaper/11102/',
        },
        {
            description: '「ゆる言語学ラジオ」がバリューブックスのアンバサダーになりました！',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/%E3%82%86%E3%82%8B%E8%A8%80%E8%AA%9E%E5%AD%A6%E3%83%A9%E3%82%B7%E3%82%99%E3%82%AA_%E3%83%A1%E3%82%A4%E3%83%B3%E3%83%92%E3%82%99%E3%82%B7%E3%82%99%E3%83%A5%E3%82%A2%E3%83%AB_%E6%A8%AA%E9%95%B7-520x292.png',
            url: '/endpaper/11097/',
        },
        {
            description: '中川政七商店 × VALUE BOOKS コラボキャンペーン — 最大8,000円！本を売って、中川政七商店のクーポンをゲットしよう！',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/295844495_1078663312768740_912650983091517423_n-520x292.jpg',
            url: '/endpaper/11059/',
        },
        {
            description: '本屋が描く世界 – VALUE BOOKS 創業15周年',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/15th_all-520x292.png',
            url: '/endpaper/11033/',
        },
        {
            description: 'ブックバスが下北に常駐！？一緒に働くメンバーを募集します！',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/%E3%83%96%E3%83%83%E3%82%AF%E3%83%90%E3%82%B9_%E4%B8%80%E7%B7%92%E3%81%AB%E5%83%8D%E3%81%8F%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%92%E5%8B%9F%E9%9B%86%E3%81%97%E3%81%BE%E3%81%99-520x292.png',
            url: '/endpaper/10986/',
        },
        {
            description: 'キャンペーンでもらえる！バリューブックスのポイント付与の流れ',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/point-520x292.jpg',
            url: '/endpaper/10932/',
        },
        {
            description: '【撮影協力】映画『流浪の月』にてバリューブックスの本が使われました！',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/ruro_no1_poster_0214-1-520x292.jpg',
            url: '/endpaper/10874/',
        },
        {
            description: '【撮影協力】映画『マイスモールランド』にてバリューブックスの本が使われました！',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/MSL_poster_fulls-520x292.jpg',
            url: '/endpaper/10850/',
        },
        {
            description: '歴史をめくると、悩みがほどけていく — 納品書のウラ書き vol45',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/NLaPr3vf-DSC_0099-520x292.jpg',
            url: '/endpaper/10903/',
        },
        {
            description: '本と音楽のフェス「 Lotus music ＆ book cafe ’22 」　ー 出演アーティストによる選書を公開します！ ー',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/nav_music-520x292.jpg',
            url: '/endpaper/10792/',
        },
        {
            description: '「ブックバスを大学に呼べますか？」',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/unnamed-4-520x292.jpg',
            url: '/endpaper/10757/',
        },
        {
            description: '【本と出会う場所】店は生活を彩る"表現の場" ／日常雑貨店「pace around」（長野県・御代田）',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/YOMIMONO_pace-around_750%C3%97450-520x292.jpg',
            url: '/endpaper/10618/',
        },
        {
            description: '【本と出会う場所】過去と未来をつなぎ、街の日常を支える暮らしの本屋／「MINOU BOOKS」（福岡県・うきは市）',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/YOMIMONO_MINOU-BOOKS_750%C3%97450-520x292.jpg',
            url: '/endpaper/10623/',
        },
        {
            description: '本屋が勧める、旬を味わう"お茶の定期便"',
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/DSC_0175-520x292.jpg',
            url: '/endpaper/10600/',
        }
    ],

    bookstoreLibraryList: [
        {
            title: '積読チャンネル',
            description: '書店員があなたの積読を増やしていくYouTubeチャンネル。次に読む本を探している方に、おすすめの１冊をお届けします！',
            image: 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/library/thumbnails/stacked-reading.jpg',
            url: '/shelf-items/folder/39658a4afb3ff85'
        },
        {
            title: '本チャンネル',
            description: '年間7万点も発売される新刊の中から気になる一冊を選び、平日ほぼ毎日紹介する番組です。本にまつわるさまざまな番組を、みんなでつくっていきます。',
            image: 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/library/thumbnails/book-channel.jpg',
            url: '/shelf-items/folder/8865700768ccf03'
        },
        {
            title: '本と茶 NABO',
            description: 'バリューブックスが運営する「本と茶 NABO（ネイボ）」のオンライン書店。新入荷の本やオリジナルグッズなど、スタッフおすすめの商品を販売中。',
            image: 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/library/thumbnails/nabo.jpg',
            url: '/shelf-items/folder/e26641c22e9b18c'
        },
        {
            title: 'ゆる言語学ラジオ書店',
            description: 'ゆるく楽しく言語の話をする「ゆる言語学ラジオ」の書店です！ラジオで紹介した書籍や、参考文献のなかでもイチオシのものを集めました。',
            image: 'https://wcdn.valuebooks.jp/ws/site/assets/images/index/library/thumbnails/linguistics.jpg',
            url: '/shelf-items/folder/c863647a6d7946b'
        },
    ],
    'officialLibraryFolderIds': [
        {
            1: 'e364e56350b470e',
            2: '7b650017b25d2fa',// 買取LP表示用
            3: '6f6513a557929d1',// オリジナルアイテムライブラリ
        }// 本番用
    ],
}

export default itemListConfig;
